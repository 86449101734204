import React from 'react'
import Layout from '../components/layout'

const NotFound = () => (
  <Layout>
    <div className="l-main">
      <main>
        <div class="error-message">
          <p class="error-message__title">Page not found.</p>
          <p class="error-message__subtitle">お探しのページは削除されたか存在しません。</p>
        </div>
      </main>
    </div>
  </Layout>
)

export default NotFound
